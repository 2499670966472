import { action, makeAutoObservable } from 'mobx'

type MenuTab = 'configurations' | 'permissions' | 'tools' | 'bugs'

export class UserInterfaceStore {
  rootStore

  gridDimensions = {
    leftSidebarWidth: 300,
    rightSidebarWidth: 300,
    headerHeight: 42,
    menuWidth: 84,
  }

  selectedMenuTab: MenuTab = 'configurations'

  // come up with breadcrumb element interface
  breadcrumbArray = []

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
    this.restoreGridDimensionsFromLocalStorage()
    this.restoreSelectedMenuTabFromLocalStorage()
  }

  @action.bound restoreGridDimensionsFromLocalStorage() {
    const storedGridDimensions = JSON.parse(localStorage.getItem('gridDimensions'))

    for (const key in storedGridDimensions) {
      if (storedGridDimensions[key] === null) {
        delete storedGridDimensions[key]
      }
    }

    this.gridDimensions = { ...this.gridDimensions, ...storedGridDimensions }
  }

  @action.bound restoreSelectedMenuTabFromLocalStorage() {
    const storedSelectedMenuTab = localStorage.getItem('selectedMenuTab')

    if (storedSelectedMenuTab) {
      this.selectedMenuTab = storedSelectedMenuTab as MenuTab
    }
  }

  @action.bound setGridDimensions(gridDimensions) {
    localStorage.setItem('gridDimensions', JSON.stringify(gridDimensions))
    this.gridDimensions = gridDimensions
  }

  @action.bound setLeftSidebarWidth(width) {
    localStorage.setItem('gridDimensions', JSON.stringify({ ...this.gridDimensions, leftSidebarWidth: width }))
    this.gridDimensions.leftSidebarWidth = width
  }

  @action.bound setRightSidebarWidth(width) {
    localStorage.setItem('gridDimensions', JSON.stringify({ ...this.gridDimensions, rightSidebarWidth: width }))
    this.gridDimensions.rightSidebarWidth = width
  }

  @action.bound setSelectedMenuTab(tab: MenuTab) {
    localStorage.setItem('selectedMenuTab', tab)
    this.selectedMenuTab = tab
    this.breadcrumbArray = [tab]
  }
}
