import { action, makeAutoObservable, runInAction } from 'mobx'
import { axiosInstance } from '../methods/axiosConfig'
import { Entity, EntityListItem } from '../types/Entity'
import { Client, ClientListItem } from '../types/Client'
import { CurrentUser, User } from '../types/User'
import { Group, GroupListItem } from '../types/Group'
import { SelectList } from '../types/General'
import { getUsersForSelect } from '../communication/Users'
import { Permission, PermissionListItem } from '../types/Permissions'
import { getGroupsList } from '../communication/Groups'
import { getPermissionsList } from '../communication/Permissions'
import { sortObj } from '../methods/sort'
import { getClientList, getEntityList } from '../communication/Configurations'

export class CommunicationStateStore {
  rootStore
  isAuthenticated = false
  initialAuthCheck = false
  currentUser: CurrentUser = {
    userId: '',
    userName: '',
    email: '',
    capabilities: [],
  }

  userSelectList: SelectList = []
  groupSelectList: SelectList = []
  permSelectList: SelectList = []

  isConfigFetchLoading = false
  clientList: ClientListItem[] = []
  entityList: EntityListItem[] = []

  usersList: User[] = []
  groupsList: GroupListItem[] = []
  permissionsList: PermissionListItem[] = []

  currentClient: Client = {} as Client
  currentEntity: Entity = {} as Entity
  currentGroup: Group = {} as Group
  currentPermission: Permission = {} as Permission

  isGroupActionLoading = false
  isUserActionLoading = false
  isPermActionLoading = false

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound setAuthorization(auth: boolean): void {
    this.isAuthenticated = auth
  }

  @action.bound logout() {
    this.initialAuthCheck = false
    this.isAuthenticated = false
    this.currentUser = {
      userId: '',
      userName: '',
      email: '',
      capabilities: [],
    }
  }

  @action.bound async checkLogin() {
    try {
      const { data } = await axiosInstance.get(`/users/current`)

      runInAction(() => {
        this.initialAuthCheck = true
        this.isAuthenticated = true
        this.currentUser = data
      })
    } catch (e) {
      runInAction(() => {
        this.initialAuthCheck = true
        this.isAuthenticated = this.rootStore.AppState.DEV_MODE
      })
      console.log(e)
    }
  }

  @action.bound async fetchUserSelectList() {
    const data = await getUsersForSelect()
    this.userSelectList = sortObj(data, 'label')
  }

  @action.bound async fetchGroupSelectList() {
    const { data } = await getGroupsList()

    const selectData = data?.groups?.map((group) => ({
      value: group.id,
      label: group.name,
    }))

    this.groupSelectList = sortObj(selectData, 'label')
  }

  @action.bound async fetchPermSelectList() {
    const { data } = await getPermissionsList()

    const selectData = data?.permissions?.map((perm) => ({
      value: `${perm.resourceType}-${perm.resourceName}-${perm.operation}`,
      label: `${perm.resourceType} - ${perm.resourceName} - ${perm.operation}`,
    }))

    this.permSelectList = sortObj(selectData, 'label')
  }

  @action.bound async fetchAllDataForInputs() {
    if (!this.currentUser?.capabilities?.includes('ACCESS_MANAGEMENT')) return

    this.fetchUserSelectList()
    this.fetchGroupSelectList()
    this.fetchPermSelectList()
  }

  @action.bound async fetchConfigurationLists() {
    runInAction(() => {
      this.isConfigFetchLoading = true
    })

    const { data: clients } = await getClientList()
    const { data: entities } = await getEntityList()

    this.setClientList(clients)
    this.setEntityList(entities)

    runInAction(() => {
      this.isConfigFetchLoading = false
    })
  }

  @action.bound setClientList(payload: ClientListItem[]) {
    this.clientList = sortObj(payload, 'name')
  }

  @action.bound setEntityList(payload: EntityListItem[]) {
    this.entityList = sortObj(payload, 'name')
  }

  @action.bound setUsersList(payload: User[]) {
    this.usersList = sortObj(payload, 'name')
  }

  @action.bound setGroupsList(payload: GroupListItem[]) {
    this.groupsList = sortObj(payload, 'name')
  }

  @action.bound setPermissionsList(payload: Permission[]) {
    this.permissionsList = payload?.map((perm) => ({
      id: `${perm?.resourceType}-${perm?.resourceName}-${perm?.operation}`,
      ...perm,
    }))
  }

  @action.bound setClient(payload: Client) {
    this.currentClient = payload
  }

  @action.bound setEntity(payload: Entity) {
    this.currentEntity = payload
  }

  @action.bound setCurrentGroup(payload: Group) {
    this.currentGroup = payload
  }

  @action.bound setCurrentPermission(payload: PermissionListItem) {
    this.currentPermission = payload
  }

  @action.bound setGroupActionLoading(payload: boolean) {
    this.isGroupActionLoading = payload
  }

  @action.bound setUserActionLoading(payload: boolean) {
    this.isUserActionLoading = payload
  }

  @action.bound setPermActionLoading(payload: boolean) {
    this.isPermActionLoading = payload
  }
}
