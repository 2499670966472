import { styled } from 'styled-components'

interface TabbedCodeViewWrapperProps {
  $horizontalWidth: string
  $isDragging?: boolean
}

const getDynamicStyles = (props: TabbedCodeViewWrapperProps) => ({
  gridTemplateColumns: `${props.$horizontalWidth} 1fr`,
  cursor: props.$isDragging ? 'col-resize' : 'default',
})

export const TabbedCodeViewWrapper = styled.div.attrs<TabbedCodeViewWrapperProps>((props) => ({
  style: getDynamicStyles(props),
}))`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 50px 1fr;
`
export const WindowContainer = styled.div<{ $isOnlyChild?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  grid-row: 2 / 3;

  ${({ $isOnlyChild }) =>
    $isOnlyChild &&
    `
    grid-column: 1 / 3;
  `}
`
