import { css, styled } from 'styled-components'
import { Animatable } from '../../../styles/generic.styles'

export const TabManagerContainer = styled.div<{ $isSplit: boolean; $splitWidth: string }>`
  grid-row: 1;
  grid-column: 1 / 3;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors['activityBar.border']};

  /* display: flex;*/
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: ${({ $isSplit, $splitWidth }) => ($isSplit ? `${$splitWidth} 1fr` : '100%')};
`

interface TabManagerColumnProps {
  $isOnlyChild?: boolean
}

export const TabManagerColumn = styled.div<TabManagerColumnProps>`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  border-right-color: ${({ theme }) => theme.colors['activityBar.border']};
  border-right-style: solid;
  border-right-width: 1px;

  &:last-child {
    border-right: none;
  }

  ${({ $isOnlyChild }) =>
    $isOnlyChild &&
    css`
      grid-column: 1 / 3;
    `}
`

export const TabsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors['activityBar.border']};
`

export const ToolboxContainer = styled.div`
  display: flex;
  height: 100%;
`

interface TabWrapProps {
  $isActive?: boolean
}

export const TabWrap = styled.div<TabWrapProps>`
  cursor: pointer;
  height: 100%;
  padding: 0 10px 0 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.colors['activityBar.inactiveForeground']};
  /* background-color: ${({ theme }) => theme.colors['editor.background']}; */
  background-color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors['tab.activeBackground'] : theme.colors['tab.hoverBackground']};
  filter: ${({ $isActive }) => ($isActive ? 'brightness(1.5)' : 'brightness(1)')};

  border-right: 1px solid ${({ theme }) => theme.colors['activityBar.border']};
  overflow: hidden;

  &:hover {
    background-color: ${({ $isActive, theme }) =>
      $isActive ? theme.colors['tab.activeBackground'] : theme.colors['tab.inactiveBackground']};

    button {
      opacity: 100;
    }
  }

  &:last-child {
    border-right: none;
  }

  ${Animatable}
`

export const TabTitle = styled.div`
  margin-bottom: 3px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

interface TabCloseButtonProps {
  $isActive?: boolean
}

export const TabCloseButton = styled.button<TabCloseButtonProps>`
  padding: 5px;
  opacity: ${({ $isActive }) => ($isActive ? '100' : '0')};
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.colors['tab.inactiveForeground']};
    color: ${({ theme }) => theme.colors['tab.inactiveBackground']};
  }

  ${Animatable}
`

export const ToolboxButton = styled.button`
  height: 100%;
  aspect-ratio: 1;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors['menubar.selectionBackground']};
  color: ${({ theme }) => theme.colors['menubar.selectionForeground']};
  border: none;
  border-right: 1px solid ${({ theme }) => theme.colors['menu.separatorBackground']};
  outline: none;
  color: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    border-right: none;
  }

  &:hover {
    filter: brightness(1.5);
  }

  ${Animatable}
`
