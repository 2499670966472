import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { StoreContext } from '../../../components/App'
import { JSONEditor } from '../../../components/JSONEditor/JSONEditor'
import { DraggableBorder } from '../../../styles/generic.styles'
import { WindowContainer } from '../TabbedCodeView.styles'

interface WindowComponentProps {
  horizontalDragBarProps?: any
  isHorizontalDragging?: boolean
  horizontalPosition?: 'left' | 'right'
}

export const WindowComponent: FC<WindowComponentProps> = observer(
  ({ horizontalDragBarProps, isHorizontalDragging, horizontalPosition }) => {
    const store = useContext(StoreContext)
    const {
      leftWindowCurrentObject,
      rightWindowCurrentObject,
      isHorizontalSplit,
      leftWindowActiveTab,
      rightWindowActiveTab,
    } = store.CodeViewState

    const activeTab = horizontalPosition === 'left' ? leftWindowActiveTab : rightWindowActiveTab

    const windowCurrentObject = horizontalPosition === 'left' ? leftWindowCurrentObject : rightWindowCurrentObject

    return (
      <WindowContainer $isOnlyChild={!isHorizontalSplit}>
        {activeTab?.type === 'get-started' && <>Get started page</>}

        {activeTab?.type === 'entity' && <JSONEditor height="100%" width="100%" value={windowCurrentObject} />}

        {activeTab?.type === 'client' && <JSONEditor height="100%" width="100%" value={windowCurrentObject} />}

        {horizontalDragBarProps && (
          <DraggableBorder {...horizontalDragBarProps} $align="right" $isDragging={isHorizontalDragging} />
        )}
      </WindowContainer>
    )
  },
)
