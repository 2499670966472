import { FC } from 'react'
import { BackButtonWrapper, StyledBackButton } from './Header.styles'

export const BackButton: FC = () => {
  return (
    <BackButtonWrapper>
      <StyledBackButton title="Back" type="button">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_74_1168)">
            <path
              d="M8 0C3.5888 0 0 3.5888 0 7.99995C0 12.4112 3.5888 16 8 16C12.4112 16 16 12.4112 16 7.99995C16 3.5888 12.4112 0 8 0ZM8 14.5455C4.39079 14.5455 1.45455 11.6092 1.45455 7.99995C1.45455 4.39084 4.39079 1.45455 8 1.45455C11.6092 1.45455 14.5455 4.39084 14.5455 7.99995C14.5455 11.6092 11.6092 14.5455 8 14.5455Z"
              fill="currentColor"
            />
            <path
              d="M11.3939 7.27202L6.36194 7.27231L8.02943 5.60477C8.31345 5.3208 8.31345 4.86029 8.02943 4.57626C7.74545 4.29229 7.28494 4.29229 7.00092 4.57626L4.09183 7.4854C3.95539 7.62184 3.87878 7.80676 3.87878 7.99968C3.87878 8.1926 3.95544 8.37757 4.09183 8.51391L7.00092 11.423C7.14293 11.565 7.32901 11.6359 7.51515 11.6359C7.70128 11.6359 7.88741 11.5649 8.02938 11.423C8.3134 11.139 8.3134 10.6785 8.02938 10.3944L6.36174 8.72681L11.394 8.72652C11.7956 8.72652 12.1212 8.40085 12.1212 7.9992C12.1212 7.5976 11.7955 7.27202 11.3939 7.27202Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_74_1168">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </StyledBackButton>
    </BackButtonWrapper>
  )
}
