import { FC, useContext } from 'react'
import { BreadcrumbItem, BreadcrumbWrapper } from './Header.styles'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../../components/App'
import { BreadcrumbSeparator } from './BreadcrumbSeparator'

export const Breadcrumb: FC = observer(() => {
  const store = useContext(StoreContext)
  const { breadcrumbArray } = store.UserInterfaceState

  const renderBreadCrumbs = () => {
    const breadcrumbs = breadcrumbArray.map((item, index) => {
      if (index === breadcrumbArray.length - 1) {
        return (
          <BreadcrumbItem $active key={index}>
            {item}
          </BreadcrumbItem>
        )
      } else {
        return [<BreadcrumbItem key={index}>{item}</BreadcrumbItem>, <BreadcrumbSeparator key={index + 'sep'} />]
      }
    })

    return breadcrumbs
  }

  return <BreadcrumbWrapper>{renderBreadCrumbs()}</BreadcrumbWrapper>
})
