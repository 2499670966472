import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { RightBarWrapper } from './RightSidebar.styles'
import { DraggableBorder } from '../../../styles/generic.styles'
import { observer } from 'mobx-react'

interface RightSidebarProps {
  dragBarProps: Omit<DetailedHTMLProps<HTMLAttributes<HTMLHRElement>, HTMLHRElement>, 'ref'>
  isResizing: boolean
}
export const RightSidebar: FC<RightSidebarProps> = observer(({ dragBarProps, isResizing }) => {
  return (
    <RightBarWrapper>
      <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Right sidebar</h1>
      <DraggableBorder {...dragBarProps} $align="left" $isDragging={isResizing} />
    </RightBarWrapper>
  )
})
