import axios, { AxiosError } from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { API_URL, STAGE } from '../constants/envs'
import {
  getClient,
  getClientList,
  getCurrentUser,
  getEntity,
  getEntityList,
  getGroupDetails,
  getGroupList,
  getOncalls,
  getPermissions,
  getUsers,
} from '../constants/mocks/requestsData'
import { toast } from 'react-toastify'

export const axiosInstanceUnauthenticated = axios.create({
  baseURL: API_URL,
})
console.log(API_URL)
export const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
axiosInstance.interceptors.response.use(undefined, async (error: any) => {
  console.error(error)
  if (error.response && error.response.status === 401 && process.env.STAGE === 'prod') {
    if (error.response?.data?.code === 'AuthorizationFailed') {
      toast.error(error.response?.data?.message)
    } else {
      console.log('401 error, redirecting to login page', `${window.location.origin}/login`)
      window.location.href = `${window.location.origin}/login`
    }
  }

  return Promise.reject(error)
})

if (STAGE === 'local') {
  // Mocking axios requests for development
  const mockAdapter = new MockAdapter(axiosInstance)

  mockAdapter.onGet('/users').reply(200, getUsers)

  mockAdapter.onGet('/users/current').reply(200, getCurrentUser)

  mockAdapter.onGet('/configurations', { params: { type: 'Client', clientId: '' } }).reply(200, getClientList)

  mockAdapter.onGet('/configurations', { params: { type: 'Entity', clientId: '' } }).reply(200, getEntityList)

  mockAdapter.onGet('/clientMock').reply(200, getClient)

  mockAdapter.onGet('/entityMock').reply(200, getEntity)

  mockAdapter.onPost('/users').reply(200, {})

  mockAdapter.onDelete('/users').reply(200, {})

  mockAdapter.onGet('/groups').reply(200, getGroupList)

  mockAdapter.onGet('/groups/test-id').reply(200, getGroupDetails)

  mockAdapter.onGet('/permissions').reply(200, getPermissions)

  mockAdapter.onGet('/on-calls').reply(200, getOncalls)
}
