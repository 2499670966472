import { FC } from 'react'
import { HeaderWrapper } from './Header.styles'
import { PinkhubMesh } from '../../../../components/Brandings/PinkhubMesh'
import { BackButton } from './BackButton'
import { Breadcrumb } from './Breadcrumb'

export const Header: FC = () => {
  return (
    <HeaderWrapper>
      <PinkhubMesh />
      <BackButton />
      <Breadcrumb />
    </HeaderWrapper>
  )
}
