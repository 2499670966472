import { FC, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { TabManagerColumn, TabManagerContainer, TabsContainer } from './TabManager.styles'
import { StoreContext } from '../../../components/App'
import { Tab } from './Tab'
import { Toolbox } from './Toolbox'

interface TabManagerProps {
  horizontalWidth?: number
}

export const TabManager: FC<TabManagerProps> = observer(({ horizontalWidth }) => {
  const store = useContext(StoreContext)
  const { leftWindowTabBarData, rightWindowTabBarData, isHorizontalSplit } = store.CodeViewState

  const leftTabs = leftWindowTabBarData.tabs.map((tab) => <Tab key={tab.id} {...tab} side="left" />)

  const rightTabs = rightWindowTabBarData.tabs.map((tab) => <Tab key={tab.id} {...tab} side="right" />)

  // #TODO: Add overflow detection to change toolbox behavior
  return (
    <TabManagerContainer $isSplit={isHorizontalSplit} $splitWidth={`${horizontalWidth}px`}>
      {isHorizontalSplit && (
        <TabManagerColumn>
          <TabsContainer>{leftTabs}</TabsContainer>
          <Toolbox isHorizontalSplit />
        </TabManagerColumn>
      )}

      <TabManagerColumn $isOnlyChild={!isHorizontalSplit}>
        <TabsContainer>{rightTabs}</TabsContainer>
        <Toolbox />
      </TabManagerColumn>
    </TabManagerContainer>
  )
})
