import { FC } from 'react'

export const BreadcrumbSeparator: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
    <g clipPath="url(#clip0_35_162)">
      <path
        d="M6.07534 3.74277L2.43898 0.106506C2.29699 -0.0355061 2.06674 -0.0355061 1.92473 0.10653C1.78274 0.248542 1.78274 0.478773 1.92475 0.620785L5.30397 3.99994L1.92473 7.37926C1.78274 7.52127 1.78274 7.7515 1.92475 7.89351C1.99573 7.96452 2.0888 8.00001 2.18186 8.00001C2.27493 8.00001 2.368 7.96452 2.439 7.89349L6.07534 4.25703C6.14356 4.18883 6.18186 4.09635 6.18186 3.99991C6.18186 3.90348 6.14356 3.81097 6.07534 3.74277Z"
        fill="#C7ABB9"
      />
    </g>
    <defs>
      <clipPath id="clip0_35_162">
        <rect width="8" height="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
