import React, { createContext, useContext, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { themeComputed } from '../themes/themeComputed'

import { rootStore } from '../store/Root.store'
import { Toasts } from './Toast/Toast'

import { observer } from 'mobx-react'
import { GlobalStyle } from '../styles/App.styles'

import { Helmet } from './Helmet'
import { MainGrid } from '../modules/MainGrid/MainGrid'
import { LoginView } from '../modules/LoginView/LoginView'

export const StoreContext = createContext(rootStore)

export const App: React.FC = observer(() => {
  const store = useContext(StoreContext)
  const { initialAuthCheck, isAuthenticated } = store.CommunicationState
  const { selectedTheme } = rootStore.AppState

  useEffect(() => {
    store.AppState.initTheme()
    store.CommunicationState.checkLogin()
  }, [])

  useEffect(() => {
    if (initialAuthCheck && !isAuthenticated) {
      //  #TODO: Render login view
    } else if (initialAuthCheck && isAuthenticated) {
      store.CommunicationState.fetchAllDataForInputs()
      store.CommunicationState.fetchConfigurationLists()
    }
  }, [initialAuthCheck, isAuthenticated])

  return (
    <StoreContext.Provider value={rootStore}>
      <Helmet title="Pinkhub" description="Pink hub application by Pink Angry Snakes" themeColor="#000000" />

      {(() => {
        console.log(selectedTheme)
        console.log(themeComputed(selectedTheme))
        return <></>
      })()}

      <ThemeProvider theme={themeComputed(selectedTheme)}>
        <GlobalStyle />
        <div className="App">
          <Toasts />

          {isAuthenticated && initialAuthCheck ? <MainGrid /> : <LoginView />}
        </div>
      </ThemeProvider>
    </StoreContext.Provider>
  )
})
