import { FC, useContext } from 'react'
import { Tab as TabInterface } from '../../../store/CodeView.store'
import { TabCloseButton, TabTitle, TabWrap } from './TabManager.styles'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../components/App'
interface TabProps {
  side: 'left' | 'right'
}

const getTabIcon = (type: string) => {
  switch (type) {
    case 'entity':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clipPath="url(#clip0_108_268)">
            <path
              d="M17.3488 19.961H2.66312C2.33582 19.961 2.07031 19.6955 2.07031 19.368V0.553895C2.07031 0.226441 2.33582 -0.0390625 2.66312 -0.0390625H13.262C13.5895 -0.0390625 13.8548 0.226441 13.8548 0.553895C13.8548 0.881349 13.5895 1.1467 13.262 1.1467H3.25607V18.7752H16.756V4.64066C16.756 4.31321 17.0213 4.0477 17.3488 4.0477C17.6763 4.0477 17.9418 4.31321 17.9418 4.64066V19.368C17.9418 19.6955 17.6763 19.961 17.3488 19.961Z"
              fill="currentColor"
            />
            <path
              d="M17.3487 5.23346H13.2619C12.9344 5.23346 12.6689 4.96796 12.6689 4.64065V0.553889C12.6689 0.31402 12.8134 0.097803 13.035 0.0060976C13.2566 -0.0857604 13.5115 -0.0349486 13.6812 0.134577L17.7678 4.22134C17.9375 4.39102 17.9882 4.64599 17.8965 4.86755C17.8048 5.08911 17.5885 5.23346 17.3487 5.23346ZM13.8547 4.0477H15.9172L13.8547 1.98516V4.0477Z"
              fill="currentColor"
            />
            <path
              d="M7.53102 11.5407C7.37752 11.5407 7.22417 11.4815 7.10805 11.3634L5.40395 9.62921C5.1772 9.3985 5.1772 9.02878 5.40395 8.79806L7.10805 7.0639C7.33754 6.83044 7.71291 6.82708 7.94652 7.05673C8.18013 7.28622 8.18334 7.66159 7.95384 7.89505L6.65807 9.21356L7.95384 10.5321C8.18334 10.7657 8.18013 11.1411 7.94652 11.3705C7.83116 11.4841 7.68102 11.5407 7.53102 11.5407Z"
              fill="currentColor"
            />
            <path
              d="M12.3672 11.5407C12.2172 11.5407 12.0672 11.4841 11.9517 11.3706C11.7182 11.1411 11.7149 10.7657 11.9444 10.5322L13.2402 9.21373L11.9444 7.89522C11.7149 7.66161 11.7182 7.28624 11.9517 7.05675C12.1853 6.82726 12.5607 6.83061 12.7902 7.06407L14.4943 8.79808C14.721 9.0288 14.721 9.39867 14.4943 9.62923L12.7902 11.3634C12.6741 11.4815 12.5207 11.5407 12.3672 11.5407Z"
              fill="currentColor"
            />
            <path
              d="M9.08163 11.5407C8.98108 11.5407 8.87915 11.5151 8.78577 11.4612C8.50226 11.2975 8.40506 10.9349 8.56879 10.6513L10.3028 7.64771C10.4665 7.3642 10.8292 7.26685 11.1127 7.43073C11.3962 7.59446 11.4934 7.95701 11.3297 8.24067L9.5957 11.2442C9.48584 11.4343 9.28656 11.5407 9.08163 11.5407Z"
              fill="currentColor"
            />
            <path
              d="M13.6393 14.3088H6.25833C5.93088 14.3088 5.66553 14.0433 5.66553 13.7158C5.66553 13.3884 5.93088 13.123 6.25833 13.123H13.6393C13.9668 13.123 14.2321 13.3884 14.2321 13.7158C14.2321 14.0433 13.9668 14.3088 13.6393 14.3088Z"
              fill="currentColor"
            />
            <path
              d="M13.6393 16.7989H6.25833C5.93088 16.7989 5.66553 16.5334 5.66553 16.2061C5.66553 15.8786 5.93088 15.6131 6.25833 15.6131H13.6393C13.9668 15.6131 14.2321 15.8786 14.2321 16.2061C14.2321 16.5334 13.9668 16.7989 13.6393 16.7989Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_108_268">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )
    case 'client':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M17.0977 0H2.90234C2.57875 0 2.31641 0.262344 2.31641 0.585938V19.4141C2.31641 19.7377 2.57875 20 2.90234 20H17.0977C17.4213 20 17.6837 19.7377 17.6837 19.4141V0.585938C17.6837 0.262344 17.4213 0 17.0977 0ZM16.5118 18.8281H3.48828V1.17188H16.5118V18.8281Z"
            fill="currentColor"
          />
          <path
            d="M11.4438 13.1821C11.1203 13.1821 10.8579 13.4444 10.8579 13.768C10.8579 14.0916 11.1203 14.354 11.4438 14.354H14.686C15.0096 14.354 15.2719 14.0916 15.2719 13.768C15.2719 13.4444 15.0096 13.1821 14.686 13.1821H11.4438Z"
            fill="currentColor"
          />
          <path
            d="M14.6859 15.526H9.15869C8.8351 15.526 8.57275 15.7883 8.57275 16.1119C8.57275 16.4355 8.8351 16.6978 9.15869 16.6978H14.6859C15.0095 16.6978 15.2718 16.4355 15.2718 16.1119C15.2718 15.7883 15.0095 15.526 14.6859 15.526Z"
            fill="currentColor"
          />
          <path
            d="M5.31421 14.354H8.66065C8.98425 14.354 9.24659 14.0916 9.24659 13.768C9.24659 13.4444 8.98425 13.1821 8.66065 13.1821H5.31421C4.99062 13.1821 4.72827 13.4444 4.72827 13.768C4.72827 14.0916 4.99062 14.354 5.31421 14.354Z"
            fill="currentColor"
          />
          <path
            d="M9.99994 12.3248C12.7534 12.3248 14.9935 10.0847 14.9935 7.33124C14.9935 4.57776 12.7534 2.33765 9.99994 2.33765C7.24646 2.33765 5.00635 4.57776 5.00635 7.33124C5.00635 10.0847 7.24646 12.3248 9.99994 12.3248ZM7.74932 10.4179C7.9726 9.37585 8.90787 8.59726 9.99994 8.59726C11.1039 8.59726 12.0296 9.36343 12.2513 10.4173C11.6194 10.8796 10.8411 11.153 9.99994 11.153C9.15916 11.153 8.38107 10.8798 7.74932 10.4179ZM9.12311 6.54854C9.12311 6.06507 9.51643 5.67171 9.99994 5.67171C10.4835 5.67171 10.8768 6.06507 10.8768 6.54854C10.8768 7.03202 10.4835 7.42538 9.99994 7.42538C9.51643 7.42538 9.12311 7.03202 9.12311 6.54854ZM9.99994 3.50952C12.1072 3.50952 13.8217 5.22394 13.8217 7.33124C13.8217 8.12288 13.5797 8.85905 13.1658 9.46972C12.9733 9.04331 12.6952 8.6562 12.3423 8.33382C12.1178 8.12882 11.8697 7.95683 11.6052 7.81882C11.8825 7.46944 12.0487 7.02819 12.0487 6.54851C12.0487 5.41886 11.1296 4.49979 9.99994 4.49979C8.87025 4.49979 7.95123 5.41886 7.95123 6.54851C7.95123 7.028 8.11701 7.4694 8.394 7.81886C8.12084 7.96147 7.86525 8.14054 7.6349 8.35468C7.29225 8.67327 7.02209 9.05269 6.83381 9.46937C6.4201 8.85874 6.17822 8.12269 6.17822 7.3312C6.17822 5.22394 7.89264 3.50952 9.99994 3.50952Z"
            fill="currentColor"
          />
        </svg>
      )

    case 'get-started':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <g clipPath="url(#clip0_63_24)">
            <path
              d="M19.9594 4.22611L19.5415 0.965231C19.5076 0.700689 19.2993 0.492399 19.0348 0.458492L15.7739 0.040623C14.2168 -0.158916 12.6245 0.38466 11.5145 1.49467L9.13586 3.87332L7.91362 3.54491C6.8035 3.24661 5.60896 3.56624 4.79615 4.37904L1.62063 7.55456C1.47265 7.70254 1.4148 7.91821 1.4689 8.12037C1.52301 8.32253 1.68079 8.4805 1.8829 8.5348L4.75345 9.30612L5.20144 9.75411L4.15194 10.8036C3.75868 11.1969 3.75868 11.8368 4.15194 12.2301L7.7699 15.848C7.96655 16.0446 8.22484 16.143 8.4831 16.143C8.74139 16.143 8.99968 16.0446 9.19633 15.848L10.2458 14.7985L10.6938 15.2465L11.4651 18.117C11.5194 18.3191 11.6774 18.4769 11.8796 18.531C12.0794 18.5849 12.2973 18.5279 12.4454 18.3793L15.6209 15.2038C16.4337 14.391 16.7534 13.1964 16.4551 12.0863L16.1267 10.8641L18.5053 8.48542C19.6154 7.37549 20.1589 5.7832 19.9594 4.22611ZM8.48314 14.9039L5.09608 11.5169L6.03011 10.5829L9.41716 13.9699L8.48314 14.9039ZM11.2191 14.1144L5.88561 8.78094L8.13801 6.52854L13.4715 11.862L11.2191 14.1144ZM5.62482 5.20775C6.1423 4.69027 6.90276 4.48682 7.60951 4.67671L8.17937 4.82984L4.88201 8.1272L3.16639 7.66621L5.62482 5.20775ZM15.3233 12.3905C15.5132 13.0973 15.3097 13.8577 14.7923 14.3752L12.3338 16.8337L11.8728 15.118L15.1702 11.8207L15.3233 12.3905ZM14.3001 11.0333L8.96667 5.69988L12.3432 2.32337C13.1984 1.46814 14.425 1.0493 15.6249 1.20305L18.4367 1.56338L18.797 4.3751C18.9508 5.5748 18.5319 6.8016 17.6767 7.65683L14.3001 11.0333Z"
              fill="currentColor"
            />
            <path
              d="M13.2602 3.72163C12.4281 4.55373 12.4281 5.90769 13.2602 6.73984C13.6763 7.15595 14.2227 7.36393 14.7693 7.36393C15.3157 7.36393 15.8624 7.15583 16.2784 6.73984C17.1105 5.90769 17.1105 4.55377 16.2784 3.72163C15.4463 2.88948 14.0923 2.88952 13.2602 3.72163ZM15.4497 5.91113C15.0746 6.28634 14.464 6.2863 14.0889 5.91113C13.7137 5.53596 13.7137 4.92547 14.0889 4.55029C14.2765 4.36271 14.5229 4.26892 14.7693 4.26892C15.0157 4.26892 15.2621 4.36271 15.4497 4.55029C15.8249 4.92547 15.8249 5.53596 15.4497 5.91113Z"
              fill="currentColor"
            />
            <path
              d="M5.17 14.8299C4.9412 14.6011 4.57017 14.6011 4.34133 14.8299L0.171628 18.9996C-0.0572094 19.2285 -0.0572094 19.5995 0.171628 19.8283C0.286047 19.9427 0.436014 19.9999 0.585942 19.9999C0.73587 19.9999 0.885876 19.9427 1.00026 19.8283L5.16996 15.6586C5.39883 15.4298 5.39883 15.0588 5.17 14.8299Z"
              fill="currentColor"
            />
            <path
              d="M1.09367 16.1576C1.24364 16.1576 1.39361 16.1004 1.50799 15.986L3.52955 13.9644C3.75839 13.7356 3.75839 13.3646 3.52955 13.1357C3.30075 12.9069 2.92972 12.9069 2.70089 13.1357L0.679319 15.1573C0.450481 15.3861 0.450481 15.7571 0.679319 15.986C0.793737 16.1004 0.943704 16.1576 1.09367 16.1576Z"
              fill="currentColor"
            />
            <path
              d="M6.03548 16.4705L4.01391 18.492C3.78508 18.7209 3.78508 19.0919 4.01391 19.3207C4.12833 19.4351 4.2783 19.4923 4.42823 19.4923C4.57816 19.4923 4.72816 19.4351 4.84254 19.3207L6.86411 17.2991C7.09295 17.0703 7.09295 16.6993 6.86411 16.4704C6.63535 16.2416 6.26432 16.2416 6.03548 16.4705Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_63_24">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )
  }
}
export const Tab: FC<TabInterface & TabProps> = observer(({ id, title, type, side }) => {
  const store = useContext(StoreContext)
  const { leftWindowActiveTab, rightWindowActiveTab } = store.CodeViewState

  const isActive =
    (leftWindowActiveTab?.id === id && side === 'left') || (rightWindowActiveTab?.id === id && side === 'right')

  return (
    <TabWrap
      $isActive={isActive}
      onClick={() => {
        store.CodeViewState.setActiveTab({ id, side })
      }}
    >
      {getTabIcon(type)}
      <TabTitle>{title}</TabTitle>
      <TabCloseButton
        $isActive={isActive}
        onClick={(e) => {
          e.stopPropagation()
          store.CodeViewState.closeTab({ id, side })
        }}
      >
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
          <path
            d="M8.73436 0.265556C8.38006 -0.0885186 7.80592 -0.0885186 7.45173 0.265556L4.47835 3.23891L1.54827 0.308606C1.19408 -0.0454687 0.619942 -0.0454687 0.265638 0.308606C-0.0885459 0.662922 -0.0885459 1.23708 0.265638 1.59128L3.19572 4.52146L0.308686 7.4086C-0.0454974 7.76291 -0.0454974 8.33708 0.308686 8.69127C0.485838 8.86831 0.71789 8.95683 0.950062 8.95683C1.18211 8.95683 1.41429 8.86831 1.59144 8.69127L4.47835 5.80426L7.40844 8.73444C7.58559 8.91148 7.81764 9 8.04982 9C8.28187 9 8.51404 8.91148 8.69119 8.73444C9.04538 8.38013 9.04538 7.80596 8.69119 7.45177L5.76111 4.52158L8.73436 1.54823C9.08855 1.19391 9.08855 0.619751 8.73436 0.265556Z"
            fill="currentColor"
          />
        </svg>
      </TabCloseButton>
    </TabWrap>
  )
})
