import { action, computed, makeAutoObservable, toJS } from 'mobx'

interface LeftSidebar {
  isOpened: boolean
}

interface RightSidebar {
  isOpened: boolean
}

export interface Tab {
  id: number
  title: string
  type: 'entity' | 'client' | 'get-started'

  //  'entity' | 'client' type
  jsonObject?: object
  // 'entity' type
  entityData?: object
  // 'client' type
  clientData?: object
}

interface WindowTabBarData {
  isFocused: boolean
  activeTab: number
  tabs: Tab[]
}

export class CodeViewStore {
  rootStore

  isHorizontalSplit = true
  horizontalWidth = 200

  leftWindowTabBarData: WindowTabBarData = {
    isFocused: false,
    // this is id, not index
    activeTab: 0,
    tabs: [
      {
        id: 0,
        title: 'Get started',
        type: 'get-started',
      },
      {
        id: 1,
        title: 'Code #1',
        type: 'entity',
        jsonObject: {
          hello: 'world',
          title: 'Code #1',
        },
      },
    ],
  }

  get leftWindowActiveTab() {
    console.log('leftWindowActiveTab', toJS(this.leftWindowTabBarData.activeTab), toJS(this.leftWindowTabBarData.tabs))
    console.log(this.leftWindowTabBarData.tabs.find((tab) => tab.id === this.leftWindowTabBarData.activeTab))

    return this.leftWindowTabBarData.tabs.find((tab) => tab.id === this.leftWindowTabBarData.activeTab)
  }

  get leftWindowCurrentObject() {
    return this.leftWindowTabBarData.tabs.find((tab) => tab.id === this.leftWindowTabBarData.activeTab)?.jsonObject
  }

  // default window
  rightWindowTabBarData: WindowTabBarData = {
    isFocused: true,
    activeTab: 3,
    tabs: [
      {
        id: 2,
        title: 'Test JSON',
        type: 'entity',
        jsonObject: {
          hello: 'world',
          title: 'Test JSON',
        },
      },
      {
        id: 3,
        title: 'Code #3',
        type: 'client',
        jsonObject: {
          hello: 'world',
          title: 'Code #3',
        },
      },
    ],
  }

  get rightWindowActiveTab() {
    return this.rightWindowTabBarData.tabs.find((tab) => tab.id === this.rightWindowTabBarData.activeTab)
  }

  get rightWindowCurrentObject() {
    return this.rightWindowTabBarData.tabs.find((tab) => tab.id === this.rightWindowTabBarData.activeTab)?.jsonObject
  }

  leftSidebar: LeftSidebar = {
    isOpened: true,
  }

  rightSidebar: RightSidebar = {
    isOpened: true,
  }

  constructor(rootStore) {
    makeAutoObservable(this, {
      leftWindowCurrentObject: computed,
      rightWindowCurrentObject: computed,
    })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
    this.restoreWindowDimensionsFromLocalStorage()
  }

  @action.bound restoreWindowDimensionsFromLocalStorage() {
    const storedHorizontalWidth = JSON.parse(localStorage.getItem('codeViewHorizontalWidth'))
    this.horizontalWidth = storedHorizontalWidth || this.horizontalWidth
  }

  @action.bound setLeftSidebarOpened(opened: boolean) {
    this.leftSidebar.isOpened = opened
  }

  @action.bound setRightSidebarOpened(opened: boolean) {
    this.rightSidebar.isOpened = opened
  }

  @action.bound toggleHorizontalSplit() {
    this.isHorizontalSplit = !this.isHorizontalSplit
  }

  @action.bound setHorizontalWidth(width: number) {
    this.horizontalWidth = width
    localStorage.setItem('codeViewHorizontalWidth', JSON.stringify(width))
  }

  @action.bound closeTab({ id, side }: { id: number; side: 'left' | 'right' }) {
    const tabPropertyName = `${side}WindowTabBarData`
    let filteredTabs = []

    filteredTabs = this[tabPropertyName].tabs.filter((tab) => tab.id !== id)
    this[tabPropertyName].tabs = filteredTabs

    if (this[tabPropertyName].activeTab === id) {
      this[tabPropertyName].activeTab = filteredTabs[0].id || undefined
    }
  }

  @action.bound setActiveTab({ id, side }: { id: number; side: 'left' | 'right' }) {
    switch (side) {
      case 'left':
        this.leftWindowTabBarData.activeTab = id
        break
      case 'right':
        this.rightWindowTabBarData.activeTab = id
        break
    }
  }
}
