import { styled } from 'styled-components'

export const RightBarWrapper = styled.section`
  grid-area: rightsidebar;
  width: 100%;
  height: 100%;
  /* border-left: 1px solid ${({ theme }) => theme.colors['activityBar.border']}; */
  background-color: ${({ theme }) => theme.colors['sideBar.background']};
  color: ${({ theme }) => theme.colors['sideBar.foreground']};
  position: relative;
`
