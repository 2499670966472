import { FC, useContext } from 'react'
import { ToolboxContainer, ToolboxButton } from './TabManager.styles'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../components/App'

interface ToolboxProps {
  isHorizontalSplit?: boolean
}

export const Toolbox: FC<ToolboxProps> = observer(({ isHorizontalSplit }) => {
  const store = useContext(StoreContext)
  const { leftWindowTabBarData, rightWindowTabBarData } = store.CodeViewState

  return (
    <ToolboxContainer>
      {!isHorizontalSplit && (
        <ToolboxButton
          title="Split view"
          onClick={() => {
            store.CodeViewState.toggleHorizontalSplit()
          }}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M1.41178 19.7647H7.7647C8.15456 19.7647 8.47059 20.0807 8.47059 20.4706C8.47059 20.8605 8.15456 21.1765 7.7647 21.1765H0.705891C0.316031 21.1765 0 20.8605 0 20.4706V3.52941C0 3.13955 0.316031 2.82352 0.705891 2.82352H7.7647C8.15456 2.82352 8.47059 3.13955 8.47059 3.52941C8.47059 3.91927 8.15456 4.2353 7.7647 4.2353H1.41178V19.7647ZM11.2941 0.705891C11.2941 0.316031 11.6101 0 12 0C12.3899 0 12.7059 0.316031 12.7059 0.705891V23.2941C12.7059 23.684 12.3899 24 12 24C11.6101 24 11.2941 23.684 11.2941 23.2941V0.705891ZM16.2353 4.2353C15.8454 4.2353 15.5294 3.91927 15.5294 3.52941C15.5294 3.13955 15.8454 2.82352 16.2353 2.82352H23.2941C23.684 2.82352 24 3.13955 24 3.52941V20.4706C24 20.8605 23.684 21.1765 23.2941 21.1765H16.2353C15.8454 21.1765 15.5294 20.8605 15.5294 20.4706C15.5294 20.0807 15.8454 19.7647 16.2353 19.7647H22.5882V4.2353H16.2353Z"
              fill="currentColor"
            />
          </svg>
        </ToolboxButton>
      )}

      <ToolboxButton title="Edit object">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M22.8503 3.03366C22.3789 2.68008 21.7981 2.53186 21.2147 2.61652C20.6315 2.70112 20.1166 3.0082 19.764 3.48263L18.1328 5.68875V0.703125C18.1328 0.314812 17.8179 0 17.4296 0H0.975098C0.586785 0 0.271973 0.314812 0.271973 0.703125V18.6094C0.271973 18.7881 0.343129 18.9658 0.466645 19.095L4.94424 23.7825C5.07614 23.92 5.262 24 5.45269 24H17.4296C17.8179 24 18.1328 23.6852 18.1328 23.2969V13.1123L23.2981 6.1028C24.0158 5.13389 23.8149 3.75708 22.8503 3.03366ZM4.74957 21.5429L2.6191 19.3125H4.74961L4.74957 21.5429ZM16.7265 22.5938H6.15582V18.6094C6.15582 18.2211 5.841 17.9062 5.45269 17.9062H1.67822V1.40625H16.7265V7.59052L10.7413 15.6848C10.6896 15.7549 10.6512 15.8339 10.6283 15.918L9.53166 19.94C9.45525 20.2203 9.55908 20.5187 9.79304 20.691C9.91669 20.7821 10.0632 20.8281 10.2101 20.8281C10.3411 20.8281 10.4723 20.7915 10.5878 20.718L14.1346 18.4583C14.2076 18.4118 14.2714 18.3522 14.3228 18.2825L16.7265 15.0206V22.5938ZM11.7186 17.2653L12.5036 17.83L11.3672 18.5541L11.7186 17.2653ZM22.1671 5.26716L13.6031 16.8886L12.2956 15.9481L20.8937 4.32009C21.0205 4.14947 21.2062 4.0387 21.4166 4.00819C21.6271 3.97777 21.8366 4.03116 22.0066 4.15866C22.3546 4.41961 22.427 4.91625 22.1671 5.26716Z"
            fill="currentColor"
          />
          <path
            d="M5.44971 8.034H13.0435C13.4318 8.034 13.7466 7.71918 13.7466 7.33087C13.7466 6.94256 13.4318 6.62775 13.0435 6.62775H5.44971C5.06139 6.62775 4.74658 6.94256 4.74658 7.33087C4.74658 7.71918 5.06139 8.034 5.44971 8.034Z"
            fill="currentColor"
          />
          <path
            d="M5.44971 10.8467H13.0435C13.4318 10.8467 13.7466 10.5319 13.7466 10.1436C13.7466 9.75524 13.4318 9.44043 13.0435 9.44043H5.44971C5.06139 9.44043 4.74658 9.75524 4.74658 10.1436C4.74658 10.5319 5.06139 10.8467 5.44971 10.8467Z"
            fill="currentColor"
          />
          <path
            d="M8.27322 12.2531H5.44971C5.06139 12.2531 4.74658 12.5679 4.74658 12.9562C4.74658 13.3445 5.06139 13.6593 5.44971 13.6593H8.27322C8.66154 13.6593 8.97635 13.3445 8.97635 12.9562C8.97635 12.5679 8.66154 12.2531 8.27322 12.2531Z"
            fill="currentColor"
          />
        </svg>
      </ToolboxButton>

      <ToolboxButton title="Copy object">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M21.7853 0H6.05768C5.6142 0 5.2533 0.360901 5.2533 0.804382V3.57166H2.25751C1.81403 3.57166 1.45312 3.93237 1.45312 4.37585V23.1956C1.45312 23.6391 1.81403 24 2.25751 24H17.9852C18.4286 24 18.7896 23.6391 18.7896 23.1956V20.4283H21.7853C22.2288 20.4283 22.5897 20.0676 22.5897 19.6241V0.804382C22.5897 0.360901 22.2288 0 21.7853 0ZM2.85938 22.5938V4.97791H13.7058V7.87006C13.7058 8.29504 14.0517 8.64093 14.4767 8.64093H17.3831V19.7249V19.7252V19.7258V22.5938H2.85938ZM15.1122 5.96814L16.3834 7.2345H15.1122V5.96814ZM21.1835 19.0221H18.7896V7.98083C18.7896 7.76624 18.7061 7.56427 18.5532 7.41119L14.9357 3.80695C14.7838 3.65515 14.5818 3.57166 14.3672 3.57166H6.65973V1.40625H21.1833V19.0221H21.1835Z"
            fill="currentColor"
          />
        </svg>
      </ToolboxButton>
    </ToolboxContainer>
  )
})
