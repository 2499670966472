import { FC, useCallback, useState } from 'react'
import { Container, Aligner, LoginButton } from './LoginView.styles'
import { LoadingSpinner } from 'authflow-ui-engine'
import { Spacer } from '../../styles/generic.styles'
import { useTheme } from 'styled-components'
import { API_URL } from '../../constants/envs'
import { SignicatLogo } from '../../components/Brandings/SignicatLogo'

export const LoginView: FC = () => {
  const theme = useTheme()

  const [redirecting, setRedirecting] = useState(false)

  const handleAuth = useCallback(() => {
    setRedirecting(true)

    window.location.href = `${API_URL}/redirect-to-auth`
  }, [])

  return (
    <Container>
      <Aligner>
        <SignicatLogo />
        <Spacer size={50}></Spacer>

        {redirecting ? (
          <LoadingSpinner
            {...theme.loadingSpinner}
            bgIframe={theme.signicatBranding.bgColor}
            bgColor={theme.signicatBranding.bgColor}
            width={50}
            height={50}
            thickness={4}
          />
        ) : (
          <LoginButton onClick={handleAuth}>Login with your Signicat account</LoginButton>
        )}
      </Aligner>
    </Container>
  )
}
